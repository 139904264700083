.customCard {
    width: 275px;
    margin: 10px;
    height: 100%;
}

.customCard>img {
    object-fit: cover;
    width: 100%;
    height: 137.5px;
}


.customCard>h2 {
    margin-left: 15px;
    font-size: 20px;
    max-width: 60px;
}

.customCard__content>p {
    text-align: left;
    margin-left: 15px;
    font-size: 14px;
}


.customCard__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.customCard__btnGrp {
    display: flex;
    justify-content: end;
    align-items: center;
}

.customCard__btn {
    padding: 10px;
}

.customCard__content {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
    margin-top: -15px;

}

.customCard_expandDescription {
    margin-left: 15px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.customCard__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.customCard__header>h2 {
    margin-left: 15px;
    font-size: 18px;
}

.customCard__footer>span {
    margin-left: 15px;
    font-size: 12px;
    color: grey;
}

.customCard__rating {
    width: 55px;
    margin-right: 15px;
}

.customCard_expandDescription {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}