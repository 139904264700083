.enrollmentForm {
    margin: 0 auto;
    border: 1px solid #e0dfdb;
    border-radius: 5px;
    padding: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.enrollmentForm__pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    font-family: inherit;
}

.enrollmentForm__form {
    display: flex;
    flex-direction: row;
}

.enrollmentForm__timings {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 10px;
    margin-top: 20px;
}


.enrollmentForm__selectedTime {
    color: #1875d1;
}


.enrollmentForm__timings__box {
    margin-top: 10px;
    width: 230px;
    border: 1px dashed #C4C4C4;
    border-radius: 5px;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
}

.enrollmentForm__timings__box > .enrollmentForm__timings__chip {
    margin: 2.5px;
}

.enrollmentForm__timings__box > .enrollmentForm__timings__chip:hover {
    color: #1875d1;
    border-color: #1875d1;
}


.enrollmentForm__course__description {
    text-align: left;
    width: 26.5ch;
    /* background-color: #4caf50; */
    padding: 5px;
}


.enrollmentForm__course__description__p {
    margin-left: -28px !important;
}