.sessionManager {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.sessionManager > hr{
    margin-top: -10px
 }
 
.sessionManager__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sessionManager__tableContainer {
    display:flex;
    align-items: center;
    /* justify-content: center; */
 }