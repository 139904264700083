.mainAppBar__workingArea {
  background-color: #f3f2ee;
  display: flex;
}

.mainAppBar__logo {
  height: 60px;
  object-fit: scale-down;
}

.mainAppBar__menuIcon {
  color: #635035;
}

.mainAppBar__heading {
  font-size: 28px;
  font-weight: 500 !important;
  color: #635035;
}

.mainAppBar__logo__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.mainAppBar__login_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainAppBar__no__current_user {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
}

.mainAppBar__content__background {
    height: 230px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(107, 35, 29, 1) 35%,
    rgba(111, 79, 50, 1) 100%
  );
}

.mainAppBar__usergreet {
  position: relative;
  bottom: 167px;
  color: white;
  text-align: initial;
  padding: 0.5;
  font-size: 32px;
}

/* Working Area */
.mainAppBar__content {
    /* min-height: 300px; */
    min-height: 64vh;
    position: relative;
    top: -190px;
    display: flex;
    flex-flow: column;
    gap: 15px;
}

.mainAppBar__content__wrapper {


}

.mainappbar__content__wrapper__animation-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .mainappbar__content__wrapper__animation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .mainappbar__content__wrapper__animation-exit {
    opacity: 1;
  }
  .mainappbar__content__wrapper__animation-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }