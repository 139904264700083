.social__media__container {
  margin-top: 20px;
}

.social__media__footer {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.social__media__icon {
  color: #634539;
}
