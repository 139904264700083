body {
  margin: 0px;
  background-color: #f3f2ee;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

