.enrollmentManager {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.enrollmentManager > hr{
    margin-top: -10px
 }


.enrollmentManager__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.enrollmentManager__fullNameCol {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.enrollmentManager__fullNameCol > span {
    margin-left: 5px;
}