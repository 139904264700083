.dashboard {
    display: flex;
    flex-direction: column;
    
}

.dashboard__colorbackground {
    background: #634539;
    height: 150px;
    margin: -24px;
    padding: 15px;
    display: flex;
    justify-content: left;
    align-items: flex-end;
}

.dashboard__greeting__heading{
    text-align: left;
    position: relative;
    bottom: 19px;
}

.dashboard__greeting__text {
    color: #F7F9FA;
    font-size: 26px;
}

.dashboard__logout__button {
    top:50px;
}

.dashboard__alert {
    position: relative;
    bottom: 24px;
    text-align: left;
}