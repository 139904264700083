.teacherManager {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
}


.teacherManager > hr{
    margin-top: -10px
 }
 
.teacherManager__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.teacherManager__tableContainer {
    display:flex;
    align-items: center;
    /* justify-content: center; */
 }